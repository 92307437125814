import { useClerk } from '@clerk/nextjs';
import { useEffect } from 'react';

// dismiss GoogleOneTap per the env provided
const useCloseGoogleOneTap = (excludedEnv?: string) => {
  const { closeGoogleOneTap } = useClerk();

  useEffect(() => {
    if (excludedEnv && process.env.NEXT_PUBLIC_ENV === excludedEnv) {
      return;
    }
    closeGoogleOneTap();
  }, [closeGoogleOneTap]);
};

export default useCloseGoogleOneTap;
