import type { ComponentRenderData } from '@plasmicapp/loader-nextjs';
import {
  extractPlasmicQueryData,
  PlasmicComponent,
  PlasmicRootProvider,
} from '@plasmicapp/loader-nextjs';
import * as _ from 'lodash';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect } from 'react';

import {
  CMS_SEO_SELECTOR,
  LANDING_PAGE_SEO_SELECTOR,
  LANDING_PAGE_SLUGS_SELECTOR,
} from '@/graphql/selectors/cms';
import useCloseGoogleOneTap from '@/hooks/useCloseGoogleOneTap';
import { Meta } from '@/layouts/Meta';
import type { LandingPageRecordModelType } from '@/models/api';
import { initializeStore } from '@/models/root-store';
// import { DEFAULT_REVALIDATE_SECONDS } from '@/shared/constant';
import type { CmsSeo } from '@/shared/types';
import { Main } from '@/templates/Main';
import { convertToFAQSchema } from '@/utils/faqFormatter';

import { PLASMIC } from '../utils/plasmic-init';
import ErrorPage404 from './404';
/* eslint-disable */

/**
 * Use fetchPages() to fetch list of pages that have been created in Plasmic
 */
const store = initializeStore();

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await PLASMIC.fetchPages();
  // must do a call here to dato to get all the slugs for landing pages
  const { allLandingPagesCms } = await store.api.queryAllLandingPagesCms(
    {},
    LANDING_PAGE_SLUGS_SELECTOR,
  );
  const slugs = allLandingPagesCms.map(
    (page: LandingPageRecordModelType) => page.slug,
  );

  return {
    paths: [
      ...pages.map((page) => ({
        params: { catchall: page.path.substring(1).split('/') },
      })),
      ...slugs.map((slug: string) => ({
        params: { catchall: ['landing', slug] },
      })),
    ],
    fallback: 'blocking',
  };
};

/**
 * For each page, pre-fetch the data we need to render it
 */
export const getStaticProps: GetStaticProps = async (context) => {
  const { catchall } = context.params ?? {};

  // Convert the catchall param into a path string
  const plasmicPath =
    typeof catchall === 'string'
      ? catchall
      : Array.isArray(catchall)
        ? `/${catchall.join('/')}`
        : '/';

  const pathSections = plasmicPath.slice(1).split('/');
  const slug = pathSections[pathSections.length - 1];
  const SITE_URL =
    process.env.NEXT_PUBLIC_SITE_URL || 'https://www.mymusclechef.com';
  const canonical = `${SITE_URL}${plasmicPath}`;

  // Fetching meta data for all plasmic pages
  const filterSlug = slug === '' ? 'home' : slug;
  const generalPageResponse = await store.api.queryGeneralPageCms(
    { filter: { slug: { eq: filterSlug } } },
    CMS_SEO_SELECTOR,
  );
  const landingPageResponse = await store.api.queryLandingPageCms(
    { filter: { slug: { eq: slug } } },
    LANDING_PAGE_SEO_SELECTOR,
  );
  const seoData: CmsSeo =
    generalPageResponse.generalPageCms || landingPageResponse.landingPageCms;

  const plasmicData = await PLASMIC.maybeFetchComponentData(plasmicPath);
  if (!plasmicData) {
    // This is some non-Plasmic catch-all page
    return {
      props: {},
    };
  }

  // This is a path that Plasmic knows about.
  const pageMeta = plasmicData.entryCompMetas[0] || {
    params: {},
    displayName: '',
  };

  // Cache the necessary data fetched for the page.
  const queryCache = await extractPlasmicQueryData(
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmicData}
      pageParams={pageMeta?.params}
    >
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>,
  );

  // Pass the data in as props.
  return {
    props: { plasmicData, queryCache, seoData, canonical },

    // Disabled due to rarity of content updates to save on costs
    // Can trigger a site rebuild or revalidate a single page instead
    // Using incremental static regeneration, will invalidate this page
    // after 300s (no deploy webhooks needed)
    // revalidate: DEFAULT_REVALIDATE_SECONDS,
  };
};

/**
 * Actually render the page!
 */
export default function CatchallPage(props: {
  plasmicData?: ComponentRenderData;
  queryCache?: Record<string, any>;
  seoData?: CmsSeo;
  canonical?: string;
}) {
  useCloseGoogleOneTap('production');

  const { plasmicData, queryCache, seoData, canonical } = props;
  const seoTitle = seoData?.seoTitle || '';
  const seoDescription = seoData?.seoDescription || '';
  const seoBlurb = seoData?.seoBlurb || '';
  const seoFAQSchema = seoData?.faq ? convertToFAQSchema(seoData.faq) : null;
  const plasmicNoIndexing =
    _.get(plasmicData, 'entryCompMetas[0].metadata.robots') ===
    'noindex,nofollow';

  const router = useRouter();
  if (!plasmicData || plasmicData.entryCompMetas.length === 0) {
    return <ErrorPage404 />;
  }
  // This is a path that Plasmic knows about.
  const pageMeta = plasmicData.entryCompMetas[0] || {
    params: {},
    displayName: '',
  };

  // If there is an anchor in the URL, scroll to it
  useEffect(() => {
    if (router.asPath.includes('#')) {
      const id = router.asPath.split('#')[1];
      if (!id) return;
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [router.asPath]);

  return (
    // Pass in the data fetched in getStaticProps as prefetchedData
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmicData}
      prefetchedQueryData={queryCache}
      pageParams={pageMeta.params}
      pageQuery={router.query}
    >
      <Main
        meta={
          <Meta
            title={seoTitle}
            description={seoDescription}
            canonical={canonical}
            seoFAQSchema={seoFAQSchema}
            noIndexing={plasmicNoIndexing}
          />
        }
        seoBlurb={seoBlurb}
      >
        <PlasmicComponent component={pageMeta.displayName} />
      </Main>
    </PlasmicRootProvider>
  );
}
