import type { NextPage } from 'next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import React from 'react';

import Button from '@/components/common/Button/Button';
import { useMediaQuery } from '@/hooks';
import { Meta } from '@/layouts/Meta';
import { Main } from '@/templates/Main';

const ErrorPage404: NextPage = () => {
  const isMobile = useMediaQuery('(max-width:1023px)');
  const imageHeight = isMobile ? 173.33 : 280;
  const imagewidth = isMobile ? 260 : 420;

  return (
    <Main
      meta={
        <Meta
          title="404 Error"
          description="This page does not exists"
          canonical={`${process.env.NEXT_PUBLIC_SITE_URL}/404`}
        />
      }
    >
      <div>
        <div className="flex w-full flex-col items-center justify-center px-[57.5px] pb-[177px] pt-[99px] lg:pt-32">
          <div className="mb-8">
            <Image
              src="/assets/images/jpg/404-page-image.jpg"
              alt="errorpage"
              height={imageHeight}
              width={imagewidth}
              className="rounded-2xl"
            />
          </div>

          <div className="mb-4 font-interMedium text-2xl leading-[30px] lg:text-4xl lg:leading-9">
            Oops!
          </div>
          <div className="mb-6 text-center font-interMedium text-base leading-[22px]">
            <p>
              Someone gobbled up what you’re looking for.
              <br className="hidden md:block" /> Let’s find something fresh to
              eat.
            </p>
          </div>
          <Link href="/" legacyBehavior>
            <div>
              <Button
                theme="primary"
                className="!rounded-32 !px-6 !py-3 !font-interMedium !text-base !leading-4"
              >
                Return Home
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </Main>
  );
};
export default ErrorPage404;
