export interface FaqQuestion {
  title: string;
  description: string;
}

export interface FAQSchema {
  '@context': string;
  '@type': 'FAQPage';
  mainEntity: Question[];
}

interface Question {
  '@type': 'Question';
  name: string;
  acceptedAnswer: Answer;
}

interface Answer {
  '@type': 'Answer';
  text: string;
}

export const convertToFAQSchema = (faqs: FaqQuestion[]): FAQSchema | null => {
  if (!faqs || !faqs.length) {
    return null;
  }
  const outputObject: FAQSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => {
      const question: Question = {
        '@type': 'Question',
        name: faq.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.description,
        },
      };
      return question;
    }),
  };

  return outputObject;
};
